import { createContext, useEffect, useState } from "react";
import { setUser as setFormUser } from "../services/forms";
//import jwt_decode from "jwt-decode";
import "core-js/stable/atob";
import { jwtDecode } from "jwt-decode";
import { getCookie, eraseCookie, setCookie } from "@utilities/cookies";
import setGADataLayer from "@utilities/setGADataLayer";
import { getUserType } from "@utilities/getUserType";
import { LOGOUT_URL, REFRESH_URL } from "../constants";

export interface UserInfo {
  exp: number;
  iat: number;
  updated_at: number;
  aud: string;
  sub: string;
  app_metadata: {
    authorization: {
      roles: string[];
    };
  };
  email: string;
}

export const UserContext = createContext({
  user: null,
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const loginUser = (user) => {
    setUser(user);
    user && setFormUser(user?.token, user?.lbsNumber);
  };

  const checkIdTokenValidity = () => {
    const idToken = getCookie("id_token");
    if (idToken) {
      const decodedAccessToken: UserInfo = jwtDecode(idToken);
      const secondsTimestamp = decodedAccessToken.exp;
      const millisecondsTimestamp = secondsTimestamp * 1000;
      if (Date.now() >= millisecondsTimestamp) {
        const loc: Location = window.location;
        const redirectUrl =
          loc.protocol + "//" + loc.host + loc.pathname + loc.search;
        setCookie({
          cookieName: "logoutRedirectUrl",
          value: redirectUrl,
          expiryDays: 999999,
        });
        window.location.href = `${LOGOUT_URL}?returnTo=logout`;
      }
    }
  };

  useEffect(() => {
    async function getRefreshToken() {
      const response = await fetch(REFRESH_URL);
    }
    if (user) {
      const tokenCheckInterval = 1 * 1000; // 1 seconds
      const refreshInterval = 420000; // 7 mins
      const intervalId = setInterval(checkIdTokenValidity, tokenCheckInterval);
      const refresh = setInterval(getRefreshToken, refreshInterval);
      return () => {
        clearInterval(intervalId);
        clearInterval(refresh);
      };
    }
  }, [user]);

  useEffect(() => {
    const token = getCookie("nf_jwt");
    const idtoken = getCookie("id_token") as string;

    if (token) {
      var decoded: UserInfo = jwtDecode(token);
      var decodedIdToken = jwtDecode(idtoken) as object;
      loginUser({
        userData: decoded,
        lbsNumber:
          decodedIdToken["lbs_no"] ||
          decodedIdToken["https://www.london.edu/lbs_no"],
        token: decoded.aud,
      });

      if (!sessionStorage.getItem("loggedIn")) {
        sessionStorage.setItem("loggedIn", "true");
        setGADataLayer({
          userId:
            decodedIdToken["lbs_no"] ||
            decodedIdToken["https://www.london.edu/lbs_no"],
          loginStatus: "logged in",
          userType: getUserType(decoded.email),
          event: "login",
        });
      }
    } else {
      eraseCookie("nf_jwt");
      eraseCookie("id_token");
      loginUser(null);
      sessionStorage.removeItem("loggedIn");
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
